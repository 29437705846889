// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/backend/providers/admin.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/backend/providers/admin.tsx");
  import.meta.hot.lastModified = "1726706773000";
}
// REMIX HMR END

// context/SidebarContext.tsx
import React, { createContext, useContext, useState } from 'react';
const AdminContext = createContext(undefined);
export const AdminProvider = ({
  children
}) => {
  _s();
  const [isAdmin, setIsAdmin] = useState(false);
  return <AdminContext.Provider value={{
    isAdmin,
    setIsAdmin
  }}>
      {children}
    </AdminContext.Provider>;
};
_s(AdminProvider, "eOJVfHUUHjCOH+0TSQ/uEMkBEGM=");
_c = AdminProvider;
export const useAdmin = () => {
  _s2();
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdmin must be used within a AdminProvider');
  }
  return context;
};
_s2(useAdmin, "b9L3QQ+jgeyIrH0NfHrJ8nn7VMU=");
var _c;
$RefreshReg$(_c, "AdminProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;